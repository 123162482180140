import { onMounted, reactive } from 'vue';
import { listenSize } from '@/composables';

export default function pageSizeController(background) {
  const pageSize = reactive({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  onMounted(() => {
    listenSize(background.value, onUpdateBackgroundSize);
  });

  const onUpdateBackgroundSize = ({ width, height }) => {
    pageSize.height = height;
    pageSize.width = width;
  };

  return {
    pageSize,
  };
}

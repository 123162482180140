<template>
  <div data-vue-component-name="Home">
    <img
      width="1920"
      height="1024"
      ref="background"
      src="backgrounds/Home.png"
      alt="page background image"
    >

    <div
      id="home-page"
      :style="{
        height: `${pageSize.height}px`,
        width: `${pageSize.width}px`,
      }"
    >
      <AppLogo />

      <transition name="fade">
        <TheTools
          v-if="isToolsVisible"
          :title="blockTitle"
          :cards="xsysList"
          @on-close="toggleToolsVisibility(false)"
        />
      </transition>

      <TheToolsButton @click="toggleToolsVisibility(true)" />
      <PreviewController />
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { AppLogo } from '@xsys2/components';
import { pageSizeController, toolsController } from '@/modules/home';
import { metaController } from '@/modules';

// meta
metaController({
  title: 'Home Page',
  link: [
    {
      rel: 'preload',
      href: 'backgrounds/Home.png',
      as: 'image',
    },
  ],
});

const store = useStore();

// page size
const background = ref(null);
const { pageSize } = pageSizeController(background);

// tools
const { isToolsVisible, toggleToolsVisibility } = toolsController();
const blockTitle = computed(() => store.state.home.tools.blockTitle);
const xsysList = computed(() => store.state.home.tools.xsysList);

if (!xsysList.value) {
  store.dispatch('home/getHomeData');
}
</script>

<style scoped lang="scss">
[data-vue-component-name="Home"] {
  position: relative;

  img {
    width: 100%;
    height: auto;
  }

  #home-page {
    @include absolute-full;

    [data-vue-component-name="TheToolsButton"] {
      position: absolute;
      top: 32.2%;
      right: 3.5%;
      z-index: 1;
    }
  }

  [data-vue-component-name="AppLogo"] {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 12.5%;
    top: 21.15%;
  }

  [data-vue-component-name="TheTools"] {
    position: relative;
    z-index: 30;
  }

  [data-vue-component-name="PreviewController"] {
    @include absolute-full;
  }
}

@media screen and (max-width: 768px) {
    [data-vue-component-name="Home"] {
        [data-vue-component-name="AppLogo"] {
            position: relative;
            left: unset;
            transform: unset;
            width: 80px;
            top: unset;
        }
    }
}
</style>

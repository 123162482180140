import { ref } from 'vue';
import { toggleServiceWrapper } from '@/modules';

export default function toolsController() {
  const isToolsVisible = ref(false);
  const serviceWrapperProps = {
    bgColor: 'rgba(0, 0, 0, 0.85)',
    blur: '8',
    style: {
      zIndex: 30,
    },
  };

  const toggleToolsVisibility = (payload) => {
    isToolsVisible.value = payload;
    toggleServiceWrapper(payload ? serviceWrapperProps : null);
  };

  return {
    isToolsVisible,
    toggleToolsVisibility,
  };
}
